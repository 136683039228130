
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { ReactNode } from "react";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
    // eslint-disable-next-line react-native/no-color-literals
    subtitleMkt: {
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '600',
        height: 22,
        lineHeight: '22px',
        fontFamily: 'Inter',
        color: 'white',
    },
    // eslint-disable-next-line react-native/no-color-literals
    descriptionMkt: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '300',
        height: 16,
        lineHeight: '16px',
        fontFamily: 'Inter',
        color: 'white',
    },
    infoContainer: {
        display: 'flex',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '4rem',
        paddingRight: '4rem',
        alignItems: 'flex-end'
    },
    infoContainerSmall: {
        display: 'flex',
        padding: '1rem',
        alignItems: 'center'
    },
    group: {
        display: 'flex',
        flexDirection: 'column', 
        marginLeft: '1rem',
        width: '80%',
    },
    groupSmall: {
        display: 'flex',
        flexDirection: 'column', 
        marginLeft: '1rem',
        width: '100%',
    },
    // eslint-disable-next-line react-native/no-color-literals
    subtitleMktSmall: {
        fontSize: '17px',
        fontStyle: 'normal',
        fontWeight: '600',
        height: 42,
        lineHeight: '22px',
        fontFamily: 'Inter',
        color: 'white',
        width: '80%',
    },
    // eslint-disable-next-line react-native/no-color-literals
    descriptionMktSmall: {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '300',
        height: 16,
        lineHeight: '16px',
        fontFamily: 'Inter',
        color: 'white',
        width: '80%',
        marginBottom: '1rem',
    },
});

type Props = {
    subtitle: string;
    description: string;
    icon: string;
    children?: ReactNode;
  };

export const InfoMarkenting = ({ subtitle, description, icon }: Props) => {

  return (
    <>
        <ResponsiveContainer breakpoint={breakpoints.small} style={commonStyles.fill}>
            {({ small }) => (

            <div style={small ? styles.infoContainerSmall : styles.infoContainer}>
                <img src={icon}  />

                <div style={small ? styles.groupSmall : styles.group}>
                    <span style={small ? styles.subtitleMktSmall : styles.subtitleMkt}>{subtitle}</span>
                    <span style={small ? styles.descriptionMktSmall : styles.descriptionMkt}>{description}</span>
                </div>
            </div>
            )}
        </ResponsiveContainer>
    </>
  )
};

import logoTrexxWhite from "../../assets/imgs/logo-trexx-white.svg";
import { OnboardingHeader } from "../../components/OnboardingHeader";
import { GetOnboardingQuery } from "../../graphql/unauthenticated";
import { StyleSheet } from "react-native";
import imgBg from "../../assets/imgs/retail-bg.svg";
import control from "../../assets/imgs/control.svg";
import receipt from "../../assets/imgs/receipt.svg";
import virtual from "../../assets/imgs/virtual.svg";
import { InfoMarkenting } from "../../components/InfoMarketing";
// import { t } from "../../utils/i18n"; TODO Add translate
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";

type Props = {
    onboarding: NonNullable<GetOnboardingQuery["onboardingInfo"]>;
};

const styles = StyleSheet.create({
    // eslint-disable-next-line react-native/no-color-literals
    container: {
        backgroundColor: '#042063',
        height: '100%',
        color: 'white',
    },
    // eslint-disable-next-line react-native/no-color-literals
    titleMkt: {
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: '700',
        height: 36,
        lineHeight: '36px',
        letterSpacing: -0.56,
        fontFamily: 'Inter',
        paddingTop: '1rem',
        paddingBottom: '4rem',
        paddingLeft: '4rem',
        paddingRight: '4rem',
        color: 'white',
    },
    group: {
        display: 'flex',
        flexDirection: 'column', 
        marginLeft: '1rem' 
    },
    // eslint-disable-next-line react-native/no-color-literals
    titleMktSmall: {
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: '700',
        height: 36,
        lineHeight: '36px',
        letterSpacing: -0.56,
        fontFamily: 'Inter',
        paddingTop: '4rem',
        paddingBottom: '5rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        color: 'white',
    }
});


export type InfoStep = {
    id: number,
    subtitle: string;
    description: string;
    icon: string;
};

const info: InfoStep[] = [
    {
      id: 1,
      subtitle: "Generador de facturas integrado",
      description: "Ahorra tiempo administrando y pagando facturas, además puedes sincronízarlas.",
      icon: receipt,
    },
    {
      id: 2,
      subtitle: "Iban virtual infinito",
      description: "Agregue subcuentas con números de cuenta designados para realizar un mejor presupuesto.",
      icon: virtual,
    },
    {
      id: 3,
      subtitle: "Controla las finanzas de tu negocio",
      description: "Sin saldo mínimo y con transacciones ilimitadas",
      icon: control
    },
  ];

export const OnboardingIndividualMarketing = ({ onboarding }: Props) => {

    const projectName = onboarding.projectInfo?.name ?? "";
    const projectLogo = logoTrexxWhite;

    return (
        <>  
        <ResponsiveContainer breakpoint={breakpoints.small} style={commonStyles.fill}>
            {({ small }) => (

            <div style={styles.container}>
                {!small && 
                    <OnboardingHeader projectName={projectName} projectLogo={projectLogo} />
                }
                    
                <div style={styles.group}>
                    <div style={small ? styles.titleMktSmall : styles.titleMkt}>Tú creaste tu negocio, nosotros le ayudamos a crecer</div>
                    
                    {
                    info.map(({ id, subtitle, description, icon }) => (
                        <InfoMarkenting key={id} subtitle={subtitle} description={description} icon={icon}> </InfoMarkenting>
                    ))
                    }   

                    <img src={imgBg} alt={projectName} />
                </div>
            </div>
            )}
        </ResponsiveContainer>
        </>
    )
}